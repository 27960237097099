import { ICondition, ICreatingForm, IFormDetails } from "@packages/types";

import { PATIENTS_EXPIRE_IN_HOURS } from "../../../../../infrastructure/shared/constants";

export const emptyForm = (): ICreatingForm => {
  return {
    hl7: false,
    patientResponseExpiresInHours: 0,
    communication: "sms",
    name: "",
    description: "",
    endMessage:
      "Your doctor will see you soon at the designated appointment time.",
    templateID: "",
  };
};

export const toFormOut = (
  form: IFormDetails | ICreatingForm,
): {
  hl7: boolean;
  name: string;
  description: string;
  template_id: string;
  patientResponseExpiresInHours: number;
  end_message: string;
  communication?: "sms" | "email";
} => {
  return {
    name: form.name ?? "",
    description: form.description ?? "",
    end_message: form.endMessage ?? "",
    template_id: form.templateID ?? "",
    communication: form.communication ?? "sms",
    hl7: form.hl7 ?? false,
    patientResponseExpiresInHours:
      form.patientResponseExpiresInHours || +PATIENTS_EXPIRE_IN_HOURS,
  };
};

export enum FlowOperators {
  Is = "is",
  Includes = "includes",
  MoreThan = "morethan",
  MoreThanOrEqualTo = "morethanorequalto",
  LessThan = "lessthan",
  LessThanOrEqualTo = "lessthanorequalto",
  DayMoreThan = "daymorethan",
  DayMoreThanOrEqualTo = "daymorethanorequalto",
  DayLessThan = "daylessthan",
  DayLessThanOrEqualTo = "daylessthanorequalto",
}

// Function to determine age from timestamp
function getAge(timestamp: Date): number {
  const timeDiff = Math.abs(Date.now() - timestamp.getTime());
  return Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
}

// Function to determine days between the provided timestamp and compare Date
function getDayDiff(timestamp: Date, compare: Date): number {
  const timeDiff = compare.getTime() - timestamp.getTime();
  return Math.round(timeDiff / (24 * 60 * 60 * 1000));
}
// Perform a test based on the condition's operator and argument
export function testCondition(
  condition: ICondition,
  response: any,
  responseTime: Date,
): boolean {
  try {
    switch (condition.operator) {
      case FlowOperators.MoreThan:
        // Determine if the field is a date, number or otherwise
        if (!isNaN(response)) {
          if (
            Number.parseInt(response) > Number.parseInt(`${condition.argument}`)
          ) {
            return true;
          }
          // Check if response is a Date
        } else if (!isNaN(new Date(response).valueOf())) {
          // Assume condition argument is a year
          return (
            getAge(new Date(response)) >
            Number.parseInt(`${condition.argument}`)
          );
          // Otherwise, assume response is string or array
        }
        return response.length > Number.parseInt(`${condition.argument}`);

      case FlowOperators.LessThan:
        // Determine if the field is a date, number or otherwise
        if (!isNaN(response)) {
          return (
            Number.parseInt(response) < Number.parseInt(`${condition.argument}`)
          );
        } else if (new Date(response).valueOf() >= 0) {
          return (
            getAge(new Date(response)) <
            Number.parseInt(`${condition.argument}`)
          );
        }
        return response.length < Number.parseInt(`${condition.argument}`);
      case FlowOperators.MoreThanOrEqualTo:
        // Determine if the field is a date, number or otherwise
        if (!isNaN(response)) {
          return (
            Number.parseInt(response) >=
            Number.parseInt(`${condition.argument}`)
          );
        } else if (new Date(response).valueOf() >= 0) {
          return (
            getAge(new Date(response)) >=
            Number.parseInt(`${condition.argument}`)
          );
        }
        return response.length >= Number.parseInt(`${condition.argument}`);
      case FlowOperators.LessThanOrEqualTo:
        // Determine if the field is a date, number or otherwise
        if (!isNaN(response)) {
          return (
            Number.parseInt(response) <=
            Number.parseInt(`${condition.argument}`)
          );
        } else if (new Date(response).valueOf() >= 0) {
          return (
            getAge(new Date(response)) <=
            Number.parseInt(`${condition.argument}`)
          );
        }
        return response.length <= Number.parseInt(`${condition.argument}`);
      case FlowOperators.Is:
        return response.toString() === condition.argument.toString();
      case FlowOperators.Includes:
        return response.includes(`${condition.argument}`);
      case FlowOperators.DayLessThan:
        return (
          new Date(response).getTime() < responseTime.getTime() &&
          getDayDiff(new Date(response), responseTime) <
            Number.parseInt(`${condition.argument}`)
        );
      case FlowOperators.DayLessThanOrEqualTo:
        return (
          new Date(response).getTime() <= responseTime.getTime() &&
          getDayDiff(new Date(response), responseTime) <=
            Number.parseInt(`${condition.argument}`)
        );
      case FlowOperators.DayMoreThan:
        return (
          new Date(response).getTime() > responseTime.getTime() &&
          getDayDiff(new Date(response), responseTime) >
            (Number.parseInt(`${condition.argument}`) + 1) * -1
        );
      case FlowOperators.DayMoreThanOrEqualTo:
        return (
          new Date(response).getTime() >= responseTime.getTime() &&
          getDayDiff(new Date(response), responseTime) >=
            (Number.parseInt(`${condition.argument}`) + 1) * -1
        );
      default:
        return false;
    }
  } catch {
    return false;
  }
}

import { useEffect, useState } from "react";
import { Control, useController } from "react-hook-form";
import { FormHelperText, FormLabel, Select } from "@chakra-ui/react";
import { useSMSTemplates } from "api/hooks/sms";

export const SMSTemplateSelect = ({
  control,
  fieldName = "templateID",
}: {
  control: Control<any, any>;
  fieldName?: string;
}) => {
  const { data: SMSTemplates } = useSMSTemplates();

  const {
    field: { onChange, value, ref },
  } = useController({
    control,
    name: fieldName,
    defaultValue: "",
  });

  useEffect(() => {
    if (SMSTemplates) {
      const defaultSMSTemplateId =
        SMSTemplates.find(({ isDefault }) => isDefault)?.id ||
        SMSTemplates[0]?.id;

      onChange(defaultSMSTemplateId, fieldName);
    }
  }, [SMSTemplates]);

  return (
    <>
      <FormLabel>Message Template</FormLabel>
      <Select
        value={value}
        ref={ref}
        aria-label="sms template select"
        onChange={(e) => onChange(e.target.value, fieldName)}
      >
        {SMSTemplates?.map((template, key) => (
          <option
            key={key}
            value={template.id}
            aria-label={`${template.name} option`}
          >
            {template.name}
          </option>
        ))}
      </Select>
      <FormHelperText>
        The template for this text message (optional).
      </FormHelperText>
    </>
  );
};

import {
  IDoctorPatients,
  IPatient,
  IPatientCreate,
  IPatientOut,
} from "@packages/types";

import { apiClient, withAuth } from "../client";
import {
  serialiseArrayResponse,
  serialisePatient,
  toPatientOut,
} from "../utils/serialisation";

export const deleteFormPatients = async (token: string, form: string) => {
  const doctors = await fetchAllPatients(token);
  Object.entries(doctors).forEach(([doctor, patients]) =>
    patients.forEach((p) => {
      if (p.form === form) {
        apiClient.delete(
          `/data/doctor/${doctor}/patient/${p.id}`,
          withAuth(token),
        );
      }
    }),
  );
  return form;
};

export const fetchPatientForDoctor = async (
  token: string,
  doctor: string,
): Promise<IDoctorPatients> => {
  const { data } = await apiClient.get(
    `/data/doctor/${doctor}/patient`,
    withAuth(token),
  );
  const parsedData: IDoctorPatients = {};
  Object.keys(data).forEach((doc) => {
    parsedData[doc] = serialiseArrayResponse<IPatient>(
      data[doc],
      serialisePatient,
    );
  });
  return parsedData;
};

export const fetchAllPatients = async (
  token: string,
): Promise<IDoctorPatients> => {
  const { data } = await apiClient.get("/data/patients", withAuth(token));
  const parsedData: IDoctorPatients = {};
  Object.keys(data).forEach((doc) => {
    parsedData[doc] = serialiseArrayResponse<IPatient>(
      data[doc],
      serialisePatient,
    );
  });
  return parsedData;
};

export const createPatient = async (
  token: string,
  doctor: string,
  patient: IPatientCreate,
): Promise<IPatient> => {
  const parsedPatient: IPatientOut = toPatientOut(patient);
  const { data } = await apiClient.post(
    `/data/doctor/${doctor}/patient`,
    {
      ...parsedPatient,
      // used to test email template
      // firstName: "Peter", doctorLastName: "Parker"
    },
    withAuth(token),
  );
  return serialisePatient(data);
};

export const deletePatient = async (
  token: string,
  doctor: string,
  patientId: string,
): Promise<{ doctor: string; patientId: string }> => {
  await apiClient.delete(
    `/data/doctor/${doctor}/patient/${patientId}`,
    withAuth(token),
  );
  return { doctor, patientId };
};

/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { isFormValid, testCondition } from "@packages/api";
import {
  ICondition,
  IFlowAction,
  IFlowItem,
  IFlowRule,
  IForm,
  IFormDetails,
  IMedspecSlide,
  IPostRule,
  IPostRuleOut,
} from "@packages/types";
import {
  FieldTypes,
  FlowActions,
  FlowOperators,
  IPatient,
  ValidationMethods,
} from "@packages/types";
import {
  UseMutateAsyncFunction,
  UseMutateFunction,
} from "@tanstack/react-query";

export interface IImportProfileField {
  incoming: string;
  type: FieldTypes;
  corresponding_slide: string;
  corresponding_field: string;
}

export interface IImportProfile {
  fields: IImportProfileField[];
}

export const ValidatorCleanNames = {
  [ValidationMethods.NonEmpty]: "Non Empty (data required)",
  [ValidationMethods.Email]: "Valid Email Address",
  [ValidationMethods.PhoneNumber]: "Valid Phone Number",
};

export const NiceActions = {
  [FlowActions.Redirect]: "Redirect",
  [FlowActions.Highlight]: "Highlight",
  [FlowActions.Counter]: "Counter",
};

export const getFlowActionArguments = (action: FlowActions): string[] => {
  switch (action) {
    case FlowActions.Counter:
      return ["name", "add"];
    case FlowActions.Highlight:
      return ["colour"];
    default:
      return [];
  }
};

export const counterArgsNicely = {
  name: "Name",
  colour: "Colour",
  add: "Increment Amount",
};

export const getFlowActionIcon = (action: FlowActions): IconName => {
  switch (action) {
    case FlowActions.Counter:
      return "flag";
    case FlowActions.Highlight:
      return "highlighter";
    default:
      return "question";
  }
};

export const NiceOperators = {
  [FlowOperators.Is]: "Is",
  [FlowOperators.Includes]: "Includes",
  [FlowOperators.MoreThan]: "Field More Than",
  [FlowOperators.MoreThanOrEqualTo]: "Field Greater or Equal to",
  [FlowOperators.LessThan]: "Field Less Than",
  [FlowOperators.LessThanOrEqualTo]: "Field Less or Equal to",
  [FlowOperators.DayMoreThan]: "Days Since More Than",
  [FlowOperators.DayMoreThanOrEqualTo]: "Days Since Greater or Equal to",
  [FlowOperators.DayLessThan]: "Days Since Less Than",
  [FlowOperators.DayLessThanOrEqualTo]: "Days Since Less or Equal to",
};

export const FieldTypeOperators = {
  [FieldTypes.Text]: [FlowOperators.Is, FlowOperators.Includes],
  [FieldTypes.Paragraph]: [FlowOperators.Is, FlowOperators.Includes],
  [FieldTypes.MultiSelect]: [FlowOperators.Includes],
  [FieldTypes.Conditional]: [FlowOperators.Is],
  [FieldTypes.Number]: [
    FlowOperators.LessThan,
    FlowOperators.LessThanOrEqualTo,
    FlowOperators.MoreThan,
    FlowOperators.MoreThanOrEqualTo,
  ],
  [FieldTypes.DateTime]: [
    FlowOperators.DayLessThan,
    FlowOperators.DayLessThanOrEqualTo,
    FlowOperators.DayMoreThan,
    FlowOperators.DayMoreThanOrEqualTo,
  ],
};

export const FlowActionProps = {
  [FlowActions.Highlight]: ["colours"],
  [FlowActions.Counter]: ["counters"],
};

export function actionPropHasOptions(
  prop: string,
  action: FlowActions,
  form?: IForm,
) {
  if (form) {
    const content = form.actions[action][prop];
    if (content) {
      if (Array.isArray(content) && content.length > 0) {
        return true;
      } else if (Object.keys(content).length > 0) {
        return true;
      }
    }
  }
  return false;
}

export const displayCondition = (
  operator: FlowOperators,
  argument: any,
): string => {
  switch (operator) {
    case FlowOperators.Is:
      return `is ${argument}`;
    case FlowOperators.Includes:
      return `includes ${argument}`;
    case FlowOperators.MoreThan:
      if (!isNaN(argument)) {
        return `is more than ${argument}`;
      } else if (new Date(argument).valueOf() >= 0) {
        return `is older than ${argument} years`;
      } else {
        return `length is more than ${argument}`;
      }
    case FlowOperators.MoreThanOrEqualTo:
      if (!isNaN(argument)) {
        return `is more than or equal to ${argument}`;
      } else if (new Date(argument).valueOf() >= 0) {
        return `is ${argument} years or older`;
      } else {
        return `length is more than or equal to ${argument}`;
      }
    case FlowOperators.LessThan:
      if (!isNaN(argument)) {
        return `is less than ${argument}`;
      } else if (new Date(argument).valueOf() >= 0) {
        return `is younger than ${argument} years`;
      } else {
        return `length is less than ${argument}`;
      }
    case FlowOperators.LessThanOrEqualTo:
      if (!isNaN(argument)) {
        return `is less than or equal to ${argument}`;
      } else if (new Date(argument).valueOf() >= 0) {
        return `is ${argument} years or younger`;
      } else {
        return `length is less than or equal to ${argument}`;
      }
    case FlowOperators.DayMoreThan:
      return `more than ${argument} day(s) ago`;
    case FlowOperators.DayMoreThanOrEqualTo:
      return `${argument} or more day(s) ago`;
    case FlowOperators.DayLessThan:
      return `less than ${argument} day(s) ago`;
    case FlowOperators.DayLessThanOrEqualTo:
      return `${argument} or less day(s) ago`;
    default:
      return `${operator} ${argument}`;
  }
};

export function emptySlide(): IMedspecSlide {
  return {
    id: "",
    name: "",
    description: "",
    fields: [],
  };
}

export function emptyCondition(): ICondition {
  return {
    field: "",
    operator: "",
    argument: "",
  };
}

export function emptyAction(): IFlowAction {
  return {
    action: undefined,
    arguments: {},
  };
}

export function emptyRedirectRule(id: string, target: string): IFlowRule {
  return {
    id,
    external: false,
    conditions: [emptyCondition()],
    then: [
      {
        action: FlowActions.Redirect,
        arguments: {
          slide: target,
        },
      },
    ],
  };
}

export function emptyPostRule(): IPostRule {
  return {
    id: "",
    conditions: [emptyCondition()],
    then: [],
  };
}

export const toFormDetails = (form: IForm): IFormDetails => {
  return form;
};

export const formsAvailable = (forms?: IForm[]): boolean => {
  if (forms === undefined) {
    return false;
  }
  if (forms.length < 1) {
    return false;
  }
  return !forms.every((form) => !isFormValid(form, forms));
};

// Perform calculations and manipulations to response based on form conditions
export function renderPatient(
  patient: IPatient | undefined,
  form: IForm | undefined,
  currentColour?: string,
): IPatient | undefined {
  if (patient !== undefined && form !== undefined) {
    if (patient.response !== undefined && patient.response[0] !== undefined) {
      const modifiedPatient = { ...patient };
      // Initialise flow variables
      modifiedPatient.data = {
        colour: currentColour,
        ...(form.actions.counter
          ? {
              counter: form.actions.counter.counters.reduce(
                (result: { key: string; value: number }, key: string) => ({
                  ...result,
                  [key]: 0,
                }),
                {},
              ),
            }
          : {}),
      };

      // Test field conditions
      form.flow?.forEach((flowItem) => {
        flowItem.rules.forEach((rule) => {
          // If every condition was met
          if (
            rule.conditions.every((condition) => {
              if (
                !modifiedPatient.response ||
                !modifiedPatient.response.length
              ) {
                return;
              }
              const resp = rule.external
                ? modifiedPatient.response[0].slides[
                    `${condition.field.replace(".", "_")}`
                  ]
                : modifiedPatient.response[0].slides[
                    `${flowItem.src}_${condition.field}`
                  ];
              return testCondition(
                condition,
                resp,
                modifiedPatient.response[0].createdAt,
              );
            })
          ) {
            // Perform defined actions
            rule.then.forEach((action) =>
              performAction(
                form,
                action,
                modifiedPatient,
                flowItem.src,
                rule.external,
              ),
            );
          }
        });
      });

      // Test form conditions
      if (form.postConditions !== undefined) {
        Object.values(form.postConditions).forEach((rule) => {
          // If every condition was met
          if (
            rule.conditions.every((condition) => {
              if (
                !modifiedPatient.response ||
                !modifiedPatient.response.length
              ) {
                return;
              }
              let resp =
                modifiedPatient.response[0].slides[
                  condition.field.replace(".", "_")
                ];
              // If value was not found in response, assume 'field' is referring to temp data
              try {
                if (resp === undefined) {
                  const split = condition.field.split(".");
                  if (split.length > 1 && split && modifiedPatient.data) {
                    resp = modifiedPatient.data[split[0]][split[1]];
                  }
                }
              } catch (e) {
                // Looks like the field wasnt at all present in the response
              }
              return testCondition(
                condition,
                resp,
                new Date(modifiedPatient.response[0].createdAt),
              );
            })
          ) {
            // Perform defined actions
            rule.then.forEach((action) =>
              performAction(form, action, modifiedPatient),
            );
          }
        });
      }
      // Return modified patient
      return modifiedPatient;
    }
    // Return normal patient
    return patient;
  }
  return undefined;
}

function performAction(
  form: IForm,
  action: IFlowAction,
  patient: IPatient,
  src?: string,
  isExternal?: boolean,
) {
  if (patient.data !== undefined) {
    switch (action.action) {
      case FlowActions.Counter:
        if (action.arguments["name"] && action.arguments["add"]) {
          if (patient.data.counter === undefined) {
            return;
          }
          if (!isNaN(patient.data.counter[action.arguments["name"]])) {
            if (!isNaN(action.arguments["add"])) {
              patient.data.counter[action.arguments["name"]] += Number.parseInt(
                action.arguments["add"],
              );
            } else {
              if (action.arguments["add"].lengthOf !== undefined) {
                const resp: any[] = [];
                if (patient.response && patient.response.length) {
                  if (isExternal !== undefined && isExternal) {
                    resp.push(
                      ...patient.response[0].slides[
                        action.arguments["add"].lengthOf.replace(".", "_")
                      ],
                    );
                  } else if (src !== undefined) {
                    resp.push(
                      ...patient.response[0].slides[
                        `${src}_${action.arguments["add"].lengthOf}`
                      ],
                    );
                  }
                }
                if (resp.length > 0) {
                  patient.data.counter[action.arguments["name"]] += resp.length;
                }
              }
            }
          }
        }
        break;
      case FlowActions.Highlight:
        if (action.arguments["colour"]) {
          const colours = form.actions.highlight.colours;
          patient.data.colour = colours[action.arguments["colour"]];
        }
        break;
    }
  }
}

interface IDeleteDependenciesProps {
  name: string;
  form: IForm;
  updateFlowItem:
    | UseMutateFunction<IFlowItem, unknown, IFlowItem, unknown>
    | UseMutateAsyncFunction<IFlowItem, unknown, IFlowItem, unknown>;
  updatePostCondition:
    | UseMutateFunction<IPostRule, unknown, IPostRuleOut, unknown>
    | UseMutateAsyncFunction<IPostRule, unknown, IPostRuleOut, unknown>;
  removePostCondition:
    | UseMutateFunction<void, unknown, string, unknown>
    | UseMutateAsyncFunction<void, unknown, string, unknown>;
}

export function deleteFlowDependencies({
  name,
  form,
  updateFlowItem,
  updatePostCondition,
  removePostCondition,
}: IDeleteDependenciesProps) {
  form?.flow?.forEach((flow) => {
    // Remove any flow rules targeting the deleted parameter
    flow.rules.forEach((rule, ruleKey) => {
      if (rule.conditions.length > 1) {
        rule.conditions.forEach((con, conKey) => {
          if (con.field.includes(name)) {
            const rules = [...flow.rules];
            rules[ruleKey].conditions.splice(conKey, 1);
            updateFlowItem({ ...flow, rules });
          }
        });
      } else {
        if (rule.conditions.some((a) => a.field.includes(name))) {
          const rules = [...flow.rules];
          rules.splice(ruleKey, 1);
          updateFlowItem({ ...flow, rules });
        }
      }
      if (rule.then.length > 1) {
        rule.then.forEach((action, actionKey) => {
          Object.values(action.arguments).forEach((arg) => {
            if (arg.includes(name)) {
              const rules = [...flow.rules];
              rules[ruleKey].then.splice(actionKey, 1);
              updateFlowItem({ ...flow, rules });
            }
          });
        });
      } else {
        if (
          rule.then.some((a) =>
            Object.values(a.arguments).some((b) => b.toString().includes(name)),
          )
        ) {
          const rules = [...flow.rules];
          rules.splice(ruleKey, 1);
          updateFlowItem({ ...flow, rules });
        }
      }
    });
  });
  if (form?.postConditions !== undefined) {
    // Remove any post conditions targeting the deleted parameter
    Object.entries(form?.postConditions).forEach(([ruleKey, rule]) => {
      if (rule.conditions.length > 1) {
        rule.conditions.forEach((con, conKey) => {
          if (con.field.includes(name)) {
            const cons = [...rule.conditions];
            cons.splice(conKey, 1);
            updatePostCondition({
              ...rule,
              id: ruleKey,
              conditions: cons,
            });
          }
        });
      } else {
        if (rule.conditions.some((a) => a.field.includes(name))) {
          removePostCondition(ruleKey);
        }
      }
      rule.then.forEach((action, actionKey) => {
        Object.values(action.arguments).forEach((arg) => {
          if (arg.includes(name)) {
            const actions = [...rule.then];
            actions.splice(actionKey);
            updatePostCondition({
              ...rule,
              id: ruleKey,
              then: actions,
            });
          }
        });
      });
    });
  }
}

interface IUpdateDependenciesProps {
  name: string;
  newName: string;
  form: IForm;
  updateFlowItem: UseMutateAsyncFunction<
    IFlowItem,
    unknown,
    IFlowItem,
    unknown
  >;
  updatePostCondition: UseMutateAsyncFunction<
    IPostRule,
    unknown,
    IPostRuleOut,
    unknown
  >;
}

export async function updateFlowDependencies({
  name,
  newName,
  form,
  updateFlowItem,
  updatePostCondition,
}: IUpdateDependenciesProps) {
  if (form.flow) {
    for await (const flow of form.flow) {
      // Update any flow rules targeting the modified parameter
      for await (const [ruleKey, rule] of flow.rules.entries()) {
        for await (const [conKey, con] of rule.conditions.entries()) {
          if (con.field.includes(name)) {
            const rules = [...flow.rules];
            rules[ruleKey].conditions[conKey].field = rules[ruleKey].conditions[
              conKey
            ].field.replace(name, newName);
            await updateFlowItem({ ...flow, rules });
          }
        }
        for await (const [actionKey, action] of rule.then.entries()) {
          const args = action.arguments;
          for await (const [argKey, arg] of Object.entries(args)) {
            if (arg.includes(name)) {
              args[argKey] = arg.toString().replace(name, newName);
            }
          }
          if (
            JSON.stringify(args) !==
            JSON.stringify(Object.entries(action.arguments))
          ) {
            const rules = [...flow.rules];
            rules[ruleKey].then[actionKey].arguments = args;
            await updateFlowItem({ ...flow, rules });
          }
        }
      }
    }
  }
  if (form.postConditions) {
    // Update any post conditions targeting the modified parameter
    for await (const [ruleKey, rule] of Object.entries(form.postConditions)) {
      for await (const [conKey, con] of rule.conditions.entries()) {
        if (con.field.includes(name)) {
          const cons = [...rule.conditions];
          cons[conKey].field = cons[conKey].field.replace(name, newName);
          await updatePostCondition({
            ...rule,
            id: ruleKey,
            conditions: cons,
          });
        }
      }
      for await (const [actionKey, action] of rule.then.entries()) {
        const args = action.arguments;
        for (const [argKey, arg] of Object.entries(args)) {
          if (arg.includes(name)) {
            args[argKey] = arg.toString().replace(name, newName);
          }
        }
        if (
          JSON.stringify(args) !==
          JSON.stringify(Object.entries(action.arguments))
        ) {
          const actions = [...rule.then];
          actions[actionKey].arguments = args;
          await updatePostCondition({
            ...rule,
            id: ruleKey,
            then: actions,
          });
        }
      }
    }
  }
}

import { IPatient, IPatientCreate, IPatientOut } from "@packages/types";

export function toPatientOut(patient: IPatientCreate): IPatientOut {
  return {
    appointment_time: patient.appointmentTime,
    phone: patient.phone,
    tenant: patient.tenant,
    form: patient.form,
    patient_email: patient.email,
    alert_targets:
      patient.alertTargets
        .filter((t) => !!t)
        .map((target) => {
          return { email: target.email, phone: target.phone };
        }) ?? [],
    template_id:
      patient.templateID === ""
        ? "00000000-0000-0000-0000-000000000000"
        : patient.templateID,
  };
}

export function serialisePatient(obj: object): IPatient {
  const patient: IPatient = {
    id: obj["id"],
    patientEmail: obj["patientEmail"],
    appointmentTime: !obj["appointment_time"]
      ? undefined
      : new Date(obj["appointment_time"]),
    phone: obj["phone"],
    tenant: obj["tenant"],
    form: obj["form"],
    createdAt: new Date(obj["created_at"]),
    updatedAt: new Date(obj["updated_at"]),
    doctor: obj["doctor_id"],
    alertTargets: obj["alert_targets"],
    response: [],
  };

  return serialisePatientResponses(patient, obj["response"]);
}

export function serialisePatientResponses(
  obj: object,
  responses: Record<string, any>,
): IPatient {
  const record = obj as IPatient;
  if (responses) {
    record.response?.push({
      slides: responses,
    });
  }

  return record;
}

import { IPatient } from "./Patient";

export interface IDoctor {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  createdAt: string;
}

export interface IDoctorOut {
  email?: string;
  phone?: string;
  isAdmin?: boolean;
}
export enum ResponseStatus {
  Recieved,
  Attention,
}

export interface IFormDetails {
  id: string;
  name: string;
  description: string;
  endMessage: string;
  templateID: string;
  hl7?: boolean;
  patientResponseExpiresInHours?: number;
  communication: "sms" | "email";
}
export interface IFormOut {
  name: string;
  description: string;
  end_message: string;
  template_id: string;
  hl7: boolean;
  patientResponseExpiresInHours: number;
}

export interface IFlowItem {
  src: string;
  defaultDest: string;
  rules: IFlowRule[];
}

export interface IFlowAction {
  action?: FlowActions;
  arguments: object;
}

export interface ICreatingFlowItem {
  src?: string;
  defaultDest?: string;
  rules?: IFlowRule[];
}

export interface IPostRule {
  id: string;
  conditions: ICondition[];
  then: IFlowAction[];
}

export interface IPostRuleOut {
  id: string;
  conditions: ICondition[];
  then: IFlowAction[];
}

export interface IMedspecSlideOut {
  id: string;
  name?: string;
  description?: string;
  starting_slide?: boolean;
  fields?: IMedspecField[];
}

export interface ICreatingMedspecSlide {
  name?: string;
  description?: string;
  startingSlide?: boolean;
  fields?: IMedspecField[];
}

export interface ICondition {
  field: string;
  operator: string;
  argument: string | number;
}
export interface IFormOut {
  name: string;
  description: string;
  end_message: string;
  template_id: string;
}

export interface ICreatingForm {
  name?: string;
  description?: string;
  endMessage?: string;
  templateID: string;
  hl7: boolean;
  communication: "sms" | "email";
  patientResponseExpiresInHours: number;
}

export interface ICreatingFormProps {
  form: ICreatingForm;
  clone?: IForm;
}

export enum ExportFilter {
  OnlyEntered = "Showing Only Entered",
  ExcludeScreening = "Exclude Screening Questions",
}

export interface IColumns {
  [form: string]: any[];
}

export enum FieldTypes {
  Unselected = "unselected",
  DateTime = "datetime",
  Text = "text",
  Number = "number",
  MultiSelect = "multiselect",
  Conditional = "conditional",
  Paragraph = "paragraph",
}

export enum ValidationMethods {
  NonEmpty = "nonEmpty",
  Email = "email",
  PhoneNumber = "phoneNumber",
}

export enum ConditionTypes {
  Equals = "equals",
  Includes = "includes",
}

export enum FlowActions {
  Redirect = "redirect",
  Counter = "counter",
  Highlight = "highlight",
}

export enum FlowOperators {
  DayLessThan = "daylessthan",
  DayLessThanOrEqualTo = "daylessthanorequalto",
  DayMoreThan = "daymorethan",
  DayMoreThanOrEqualTo = "daymorethanorequalto",
  LessThan = "lessthan",
  LessThanOrEqualTo = "lessthanorequal",
  MoreThan = "morethan",
  MoreThanOrEqualTo = "morethanorequalto",
  Is = "is",
  Includes = "includes",
}

export enum ActionArguments {
  Colour = "colour",
  Add = "add",
  LengthOf = "lengthOf",
  Name = "name",
  Slide = "slide",
}

export interface IMedspecValidator {
  method: ValidationMethods;
  condition?: string;
}

export interface IMedspecField {
  id: string;
  name: string;
  type: FieldTypes;
  placeholder?: string;
  validators: IMedspecValidator[];
  choices?: string[];
}

export interface IMedspecSlide {
  id: string;
  name: string;
  description?: string;
  startingSlide?: boolean;
  fields?: IMedspecField[];
}

export interface IEditingCondition {
  key?: string;
  creating: boolean;
}

export interface ICondition {
  field: string;
  operator: string;
  argument: string | number;
}

export interface IAction {
  action?: FlowActions;
  args: object;
}

export interface IFlowRule {
  id: string;
  external?: boolean;
  conditions: ICondition[];
  then: IFlowAction[];
}
export interface IFlowItem {
  src: string;
  defaultDest: string;
  rules: IFlowRule[];
}

export interface IFlowAction {
  action?: FlowActions;
  arguments: object;
}

export interface IParameterGroup {
  [key: string]: any | string[] | number[];
}

export interface IForm {
  id: string;
  name: string;
  description: string;
  endMessage: string;
  version: number;
  actions: { [id: string]: IParameterGroup };
  slides: { [id: string]: IMedspecSlide };
  flow?: IFlowItem[];
  postConditions?: { [id: string]: IPostRule };
  alert_config?: IFormAlertConfig;
  templateID: string;
  rawSlides?: any;
  hl7?: boolean;
  patientResponseExpiresInHours?: number;
  communication: "sms" | "email";
}

export interface IFormAlertConfig {
  enabled: boolean;
  name_slide: string;
  name_field: string;
}

export interface IMedspecValidator {
  method: ValidationMethods;
  condition?: string;
}

export interface IMedspecField {
  id: string;
  name: string;
  type: FieldTypes;
  placeholder?: string;
  validators: IMedspecValidator[];
  choices?: string[];
}

export function emptyField(): IMedspecField {
  return {
    id: "",
    name: "",
    type: FieldTypes.Text,
    validators: [],
    choices: [],
  };
}

export interface IColumn {
  Header: string;
  name?: string;
  id?: string;
  accessor?: keyof IPatient | string;
  required?: boolean;
  disableSortBy?: boolean;
  width: number;
  fieldName?: string;
  slide?: string;
  type?: FieldTypes;
  sorting?: boolean;
}
